import React, { Component } from "react"
import FormMaker from "../../../components/formMaker"
import { connect } from "react-redux"
import { storeCurrentRequestId } from "../../../redux/actions/"
import { Steps } from "intro.js-react"
import IntroWrapper from '../../../components/client-only-wrapper';

export class RoyaltyFlyerPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      options: {
        showProgress: true,
        dontShowAgain: true,
        showBullets: false,
        scrollToElement: false,
      },
      showUsers: true,
      initialStep: 0,
      steps: [
        {
          element: "#venuetype",
          title: "Event Type",
          intro:
            "Don't forget to select if the event is real or virtual if its virtual then do please provide the zoom /meeting link",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          element: "#entertainment",
          title: "Entertainment",
          intro:
            "If there is entertainment please do supply any images you would like to have included on your flyer. Further down the form is an area where you can load all your images",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          element: "#topics",
          title: "Topics",
          intro:
            "Select all the topics that will be included in your Royalty or write your own in the space provided below",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          element: "#selectfiles",
          title: "Event Speakers",
          intro: "Please do load the photographs of your speaker(s) here.",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          element: ".fileupload",
          title: "Upload File",
          intro:
            "This is where you can load all your images required to go on the flyer such as entertainment, prizes, venue, food etc",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          element: ".submitLater",
          title: "NOTE:",
          intro:
            "You can save your progress here. Just remember that for it to be sent to head office you have to select the 'submit form' button",
          position: "top",
          tooltipClass: "intro-title",
        },
      ],
    }
  }

  toggleSteps = () => {
    this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }))
  }

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }))
  }
  render() {
    const {
      stepsEnabled,
      steps,
      initialStep,
      options,
      scrollToElement,
    } = this.state
    console.log(this.props)
    return (
      <div>
        <IntroWrapper>
          <Steps
            scrollToElement={scrollToElement}
            options={options}
            enabled={stepsEnabled}
            steps={steps}
            initialStep={initialStep}
            onExit={this.onExit}
          />
        </IntroWrapper>

        <button
          className="fixed bottom-20 right-20 h-28 w-28 bg-brand-blue rounded-full text-white font-bold flex items-center justify-center shadow-lg z-50 font-medium"
          onClick={this.toggleSteps}
        >
          <span className="font-medium">Need Help?</span>
        </button>
        <FormMaker
          formId="602d9017ba5f15001bb52fae"
          formType="royalty-flyers"
          id={`${"venuetype"} ${"entertainment"} ${"topics"} ${"selectfiles"}`}
          submitLater="submitLater"
          fileupload="fileupload"
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  current_request_id: state.current_request_id,
})

function mapDispatchToProps(dispatch) {
  return {
    storeCurrentRequestId: id => dispatch(storeCurrentRequestId(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoyaltyFlyerPage)
